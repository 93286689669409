<template>
  <div id="events-preview">
    <v-toolbar color="#8950FC">
      <v-toolbar-title>
        <h1 class="text-white">{{ pageTitle }}</h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-card-title>
        <!-- <span class="headline">{{ pageDescription }}</span> -->
      </v-card-title>

      <v-card-text>
        <v-dialog
          eager
          v-model="scoreRecordPrompt"
          v-if="PreviewButtonFlag && Documentpath != ''"
        >
          <v-app-bar
            color="primary"
            dense
            dark
            elevate-on-scroll
            scroll-target="#scrolling-techniques-7"
          >
            <v-toolbar-title>Nomination List</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon dark @click="hideScoreRecordPrompt">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-app-bar>
          <iframe
            id="ifrm"
            :src="Documentpath"
            v-if="PreviewButtonFlag && Documentpath != ''"
            width="100%"
            height="500px"
          ></iframe>
          <v-card v-if="!PreviewButtonFlag" width="100%" height="500px">
            <v-row wrap>
              <v-col cols="12" md="12" align="center">
                <h1 class="text-danger">File not found.</h1>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>

        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>

        <v-container v-if="ResultFlag">
          <v-row wrap>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Submitted Date</h5>
              <p class="preview-content">{{ row.UpdatedAtTxt }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Portfolio</h5>
              <p class="preview-content">{{ row.PortfolioName }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Category</h5>
              <p class="preview-content">{{ row.EventTypeName }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Sub Category</h5>
              <p class="preview-content">{{ row.EventSubTypeName }}</p>
            </v-col>
          </v-row>
          <hr />
          <v-row wrap>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Event Date</h5>
              <p class="preview-content">
                {{ row.DateFromTxt }} -
                {{ row.DateToTxt == 1 ? row.DateToTxt : "" }}
              </p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Event Type</h5>
              <p class="preview-content">{{ row.OrganizationTypeName }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">LOM</h5>
              <p class="preview-content">
                {{ row.ZoneName }} - {{ row.LomName }}
              </p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">100% efficency</h5>
              <p class="preview-content">{{ row.SubCriteriaTxt }}</p>
              <p class="preview-content">
                {{ row.PointsTypeName }} - {{ row.PointsValue }} pts
              </p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Event Name</h5>
              <p class="preview-content">{{ row.JciEventName }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Venue</h5>
              <p class="preview-content">{{ row.VenueDetails }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Invitation</h5>
              <viewer rebuild :images="row.InvitationImages">
                <img
                  style="padding: 5px"
                  width="200"
                  height="200"
                  :src="src"
                  :key="src"
                  v-for="src in row.InvitationImages"
                />
              </viewer>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Group photo</h5>
              <viewer rebuild :images="row.WebsiteImages">
                <img
                  style="padding: 5px"
                  width="200"
                  height="200"
                  :src="src"
                  :key="src"
                  v-for="src in row.WebsiteImages"
                />
              </viewer>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12">
              <h5 class="blue--text text--lighten">Description</h5>
              <p class="preview-content">{{ row.JciEventDescription }}</p>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" sm="6" lg="6" md="6">
              <h5 class="blue--text text--lighten">Event Photos</h5>
              <viewer rebuild :images="row.ParticipationImages">
                <img
                  v-for="src in row.EventImages"
                  width="100"
                  height="100"
                  :src="src"
                  :key="src"
                />
              </viewer>
              <!-- <v-data-iterator
                :items="DocumentsData"
                :items-per-page="-1"
                hide-default-footer
              >
                <template v-slot:header> </template>

                <template v-slot:default="props">
                  <v-row>
                    <v-col
                      v-for="document in props.items"
                      :key="document.EventDocumentId"
                      cols="12"
                      sm="6"
                      md="4"
                      lg="3"
                    >
                      <v-card raised hover ripple elevation="25">
                        <v-card-text>
                          <v-avatar size="100">
                            <img
                              :src="document.DocumentPublicPath"
                              :alt="document.DocumentTypeName"
                            />
                          </v-avatar>
                          <h3>{{ document.DocumentTypeName }}</h3>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator> -->
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" sm="6" lg="4" md="4">
              <h5 class="blue--text text--lighten">Event Chairman</h5>
              <div v-if="row.ChairmanFlag">
                <v-card raised hover ripple elevation="25">
                  <v-card-text class="text-center">
                    <v-avatar size="100">
                      <img
                        :src="ChairmanData.ChairmanMemberPublicPath"
                        :alt="ChairmanData.ChairmanMemberFullname"
                        v-if="ChairmanData.ChairmanMemberPublicPath != ''"
                      />
                    </v-avatar>
                    <h3>
                      {{ ChairmanData.ChairmanMemberFullname }}
                    </h3>
                    <h3 class="text-primary">
                      {{ ChairmanData.ChairmanMemberDetails }}
                    </h3>
                  </v-card-text>
                </v-card>
              </div>
              <div v-else>NA</div>
            </v-col>
            <v-col cols="12" sm="6" lg="4" md="4">
              <h5 class="blue--text text--lighten">Event Director</h5>
              <div v-if="row.DirectorFlag">
                <v-card raised hover ripple elevation="25">
                  <v-card-text class="text-center">
                    <v-avatar size="100">
                      <img
                        :src="DirectorData.DirectorMemberPublicPath"
                        :alt="DirectorData.DirectorMemberFullname"
                        v-if="DirectorData.DirectorMemberPublicPath != ''"
                      />
                    </v-avatar>
                    <h3>
                      {{ DirectorData.DirectorMemberFullname }}
                    </h3>
                    <h3 class="text-primary">
                      {{ DirectorData.DirectorMemberDetails }}
                    </h3>
                  </v-card-text>
                </v-card>
              </div>
              <div v-else>NA</div>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12">
              <h5 class="blue--text text--lighten">Dignitaries</h5>
              <v-data-iterator
                :items="FacultiesData"
                :items-per-page="-1"
                hide-default-footer
              >
                <template v-slot:header> </template>

                <template v-slot:default="props">
                  <v-row>
                    <v-col
                      v-for="faculty in props.items"
                      :key="faculty.EventFacultyId"
                      cols="12"
                      sm="6"
                      md="4"
                      lg="3"
                    >
                      <v-card raised hover ripple elevation="25">
                        <v-card-text class="text-center">
                          <v-avatar size="100">
                            <img
                              :src="faculty.FacultyMemberPublicPath"
                              :alt="faculty.FacultyMemberName"
                            />
                          </v-avatar>
                          <h3>
                            {{ faculty.FacultyMemberName }}
                          </h3>
                          <h3>
                            {{ faculty.FacultyTypeName }}
                          </h3>
                          <h3 class="text-primary">
                            {{ faculty.FacultyMemberDetails }}
                          </h3>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
            </v-col>
          </v-row>

          <v-dialog eager v-model="FilePreviewFlag" v-if="FilePreviewFlag">
            <pdf-document-file-preview
              PageTitle="File Preview"
              :PreviewButtonFlag="FilePreviewFlag"
              :Documentpath="row.ConsolidateFilePath"
              @hideDialog="hideFilePreview"
              v-if="FilePreviewFlag"
            ></pdf-document-file-preview>
          </v-dialog>

          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                v-if="row.ConsolidateFilePath != ''"
                @click="FilePreviewFlag = true"
                color="primary"
                elevation="30"
                shaped
                tile
                large
                class="
                  btn btn-primary
                  font-size-h6
                  px-15
                  py-4
                  my-3
                  mr-3
                  white--text
                "
              >
                Uploaded file preview
              </v-btn>
            </v-col>
          </v-row>

          <br />
          <hr />

          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12" align="center">
              <h4>LOM information for the current year</h4>
            </v-col>
          </v-row>

          <v-dialog
            eager
            v-model="LomDataPreviewFlag"
            v-if="LomDataPreviewFlag"
          >
            <lom-information
              :PageTitle="LomDataPageTitle"
              :recordData="LomDataParams"
              @hideDialog="hideLomDataPreview"
              v-if="LomDataPreviewFlag"
            ></lom-information>
          </v-dialog>

          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                v-if="row.LomCode != ''"
                @click="showLomDataPreview"
                color="success"
                elevation="30"
                shaped
                tile
                large
                class="
                  btn btn-primary
                  font-size-h6
                  px-15
                  py-4
                  my-3
                  mr-3
                  white--text
                "
              >
                Show LOM Data
              </v-btn>
            </v-col>
          </v-row>

          <br />
          <hr />

          <v-container>
            <v-row wrap>
              <v-col cols="12" sm="12" lg="12" md="12" align="center">
                <h4>For administrative purpose only</h4>
              </v-col>
            </v-row>
            <v-form
              ref="form2"
              v-model="valid1"
              lazy-validation
              v-on:submit.prevent="searchForm"
            >
              <v-row wrap>
                <v-col cols="12" sm="12" lg="6" md="6">
                  <label>
                    <h6><span class="text-danger">*</span> 100% Efficiency</h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    v-model="EfficiencyId"
                    :rules="EfficiencyIdRules"
                    :items="EfficiencyIdOptions"
                    :loading="EfficiencyIdOptionsLoading"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" lg="6" md="6">
                  <label>
                    <h6><span class="text-danger">*</span>Remarks</h6>
                  </label>
                  <v-text-field
                    v-model="Remarks"
                    :rules="RemarksRules"
                    hint="Enter the remarks"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="submitForm(1)"
                    :loading="ApproveLoadingFlag"
                    color="success"
                    elevation="30"
                    shaped
                    tile
                    large
                    class="
                      btn btn-primary
                      font-size-h6
                      px-15
                      py-4
                      my-3
                      mr-3
                      white--text
                    "
                  >
                    Approve
                  </v-btn>
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="submitForm(2)"
                    :loading="RejectLoadingFlag"
                    color="warning"
                    elevation="30"
                    shaped
                    tile
                    large
                    class="
                      btn btn-primary
                      font-size-h6
                      px-15
                      py-4
                      my-3
                      mr-3
                      white--text
                    "
                  >
                    Not eligible
                  </v-btn>
                  <v-btn
                    @click="closePrompt"
                    color="primary"
                    elevation="30"
                    shaped
                    tile
                    large
                    class="
                      btn btn-primary
                      font-size-h6
                      px-15
                      py-4
                      my-3
                      mr-3
                      white--text
                    "
                  >
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn color="blue darken-1" text @click="closePrompt"> Cancel </v-btn> -->
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  components: {},
  props: {
    scoreRecordPrompt: {
      type: Boolean,
      required: true,
    },
    recordData: {
      required: true,
    },
    pageTitle: {
      type: String,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      tableColumns1: [],
      tableOptions1: {},
      tableData1: [],

      valid1: true,

      row: {},
      ResultFlag: false,

      PreviewButtonFlag: false,
      Documentpath: "",

      LoadingFlag: false,

      TableFlag1: false,

      selected: [],
      SearchFlag: false,
      search: "",

      ChairmanData: {},
      DirectorData: {},
      DocumentsData: [],
      FacultiesData: [],

      EfficiencyIdRules: [(v) => !!v || "100% Efficiency criteria is required"],
      EfficiencyId: "",
      EfficiencyIdOptions: [],
      EfficiencyIdOptionsLoading: false,

      Remarks: "",
      RemarksRules: [(v) => !!v || "Remarks is required"],

      ApproveLoadingFlag: false,
      RejectLoadingFlag: false,
      FilePreviewFlag: false,

      LomDataPageTitle: "",
      LomDataPreviewFlag: false,
      LomDataParams: {},
    };
  },
  computed: {},
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    row: function () {
      console.log("watch row");
      var tr = this.row;
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      this.EfficiencyId = "";
      if (n1 > 0) {
        this.EfficiencyId = tr.EfficiencyId;
        this.getEfficiencyIdOptions();
      }
    },
    EfficiencyIdOptions: function () {
      console.log("watch EfficiencyIdOptions");
      this.LoadingFlag = false;
      this.EfficiencyIdOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideScoreRecordPrompt");
    },
    validate() {
      console.log("validate is called");
      var result = this.$refs.form.validate();
      console.log("result=" + result);
      if (result) {
        this.addRecordDetails();
      } else {
        console.log("some errors occured");
      }
    },
    hideScoreRecordPrompt() {
      console.log("hideScoreRecordPrompt called");
      this.scoreRecordPrompt = false;
    },
    hideFilePreview() {
      console.log("hideFilePreview called");
      this.FilePreviewFlag = false;
    },
    showLomDataPreview() {
      console.log("showLomDataPreview called");
      var tr = this.row;
      console.log({ tr });
      this.LomDataPageTitle = tr.LomName + " information for the current year";
      this.LomDataPreviewFlag = true;
      this.LomDataParams = {
        UserInterface: 1,
        LomCode: tr.LomId,
      };
    },
    hideLomDataPreview() {
      console.log("hideLomDataPreview called");
      this.LomDataPreviewFlag = false;
    },
    previewData(tr) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.Documentpath = tr.DocumentAssetPath;
        this.scoreRecordPrompt = true;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      var recordData = this.recordData;
      console.log("recordData=" + JSON.stringify(recordData));
      this.getTableRecords();
    },
    getEfficiencyIdOptions() {
      console.log("getEfficiencyIdOptions called");
      var yearCode = this.row.YearId;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.LoadingFlag = true;
        this.EfficiencyIdOptionsLoading = true;
        var selectbox1_source = "EfficiencyId";
        var selectbox1_destination = "EfficiencyIdOptions";
        var selectbox1_url = "api/year-wise-efficiency/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          Year: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var JciEventId = this.recordData.JciEventId;
      console.log("JciEventId=" + JciEventId);

      if (JciEventId != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-events/show";
        var upload = {
          UserInterface: 1,
          EventCode: JciEventId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.row = {};
        this.ChaimanData = {};
        this.DirectorData = {};
        this.DocumentsData = [];
        this.FacultiesData = [];
        this.ResultFlag = false;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.LoadingFlag = true;
        this.Documentpath = "";

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            var options = response.data.options;
            var TableHeader = options.TableHeader;
            var TableOptions = options.TableOptions;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.ResultFlag = flag;
            thisIns.tableColumns1 = TableHeader;
            thisIns.tableOptions1 = TableOptions;

            if (flag == 1) {
              thisIns.row = records;
              thisIns.ChairmanData = records.ChairmanData;
              thisIns.DirectorData = records.DirectorData;
              thisIns.DocumentsData = records.Documents;
              thisIns.FacultiesData = records.Faculties;
              // thisIns.toast("success", output);
            } else {
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Your session is expired. Kindly login again. ";
        console.log("error=" + message);
      }
    },
    submitForm(Status) {
      console.log("submitForm is called");

      console.log({ Status });

      var message = "";

      var validate1 = this.$refs.form2.validate();
      console.log({ validate1 });

      var JciEventId = this.recordData.JciEventId;
      console.log({ JciEventId });

      // var validate1 = false;

      if (validate1 && Status > 0 && JciEventId > 0) {
        switch (Status) {
          case 1:
            this.ApproveLoadingFlag = true;
            break;

          case 2:
            this.RejectLoadingFlag = true;
            break;

          default:
            break;
        }

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-event-score/store";
        var upload = {
          UserInterface: Status,
          EventId: JciEventId,
          EfficiencyId: this.EfficiencyId,
          Remarks: this.Remarks,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var table = {};
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            switch (Status) {
              case 1:
                thisIns.ApproveLoadingFlag = false;
                break;

              case 2:
                thisIns.RejectLoadingFlag = false;
                break;

              default:
                break;
            }

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            table = response.data.TableContent;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        if (!validate1) {
          message = "Kindly fill the required fields. ";
        }
        if (Status == null || Status == "") {
          message = "Status is invalid. ";
        }
        if (JciEventId == null || JciEventId == "") {
          message = "Event id is invalid. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#events-preview {
  .preview-content {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5em;
  }
}
</style>